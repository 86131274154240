import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Photo } from "../../models";
import {
  BackgroundImage,
  BackgroundImagePosition,
} from "../background-image";
import { useWindowSize } from "../hooks/useWindowSize";
import { Loader } from "../loader";

import "./BackgroundSlider.component.scss";

const BACKGROUND_SLIDER_INTERVAL = 5000; // ms
const MAX_WIDTH = 768; // px

interface BackgroundPhoto extends Photo {
  position: BackgroundImagePosition;
}

export const BackgroundSlider = (): JSX.Element => {
  const photos: BackgroundPhoto[] = [
    {
      file: "/img/background-slider/GerminalProd7janvier_1359.jpg",
      position: BackgroundImagePosition.Center,
      loaded: false,
    },
    {
      file: "/img/background-slider/GerminalProd7janvier_1341.jpg",
      position: BackgroundImagePosition.Center,
      loaded: false,
    },
    {
      file: "/img/background-slider/_DSC2075 copie.jpg",
      position: BackgroundImagePosition.Center,
      loaded: false,
    },
    {
      file: "/img/background-slider/GerminalProd14décembre_1068.jpg",
      position: BackgroundImagePosition.Top,
      loaded: false,
    },
    {
      file: "/img/background-slider/GerminalProd22février_3403.JPG",
      position: BackgroundImagePosition.Top,
      loaded: false,
    },
    {
      file: "/img/background-slider/L'Absente23mars_4582.JPG",
      position: BackgroundImagePosition.Center,
      loaded: false,
    },
    {
      file: "/img/background-slider/L'AbsenteProd3dec_585.JPG",
      position: BackgroundImagePosition.Top,
      loaded: false,
    },
  ];
  const [index, setIndex] = useState<number>(0);
  const [photosLoaded, setPhotosLoaded] = useState<Array<boolean>>(
    Array<boolean>(photos.length).fill(false),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWdth, windowHeight] = useWindowSize();

  const slide = () => {
    const newIndex = index + 1;
    if (photosLoaded[(newIndex % photosLoaded.length)]) {
      setIndex(newIndex);
    }
  };

  const handleImgLoad = (imgIndex: number) => () => {
    const loadedPhotos = [...photosLoaded];
    loadedPhotos[imgIndex] = true;
    setPhotosLoaded(loadedPhotos);
  };

  useEffect(() => {
    const timer = setInterval(() => slide(), BACKGROUND_SLIDER_INTERVAL);
    return () => clearInterval(timer);
  });

  const images = photos.map((photo, i) => {
    const isCurrentPhoto = i === (index % photos.length);
    const isVisible = photosLoaded[0] && (isCurrentPhoto || (index > 0 && i === (index - 1)));
    return (
      <BackgroundImage
        key={photo.file}
        src={photo.file}
        position={photo.position}
        className={classNames("background-slider__img", {
          "background-slider__img--visible": isVisible,
          "background-slider-fade-in": isCurrentPhoto,
        })}
        onLoad={handleImgLoad(i)}
        fitScreen={isMobile || windowWdth < MAX_WIDTH}
      />
    );
  });

  return (
    <div className="background-slider">
      {!photosLoaded[0] ? <Loader /> : null}
      {images}
    </div>
  );
};
